import React from "react";
import { Link } from "gatsby";
import ISO_9001Img from "../../assets/Images/Footer/updated/ISO_9001.svg";
import ISO_27001Img from "../../assets/Images/Footer/updated/ISO-27001.svg";
import HMImg from "../../assets/Images/Footer/updated/hm.svg";

import FacebookIcon from "../../assets/Images/Footer/updated/fb.svg";
import LinkedInIcon from "../../assets/Images/Footer/updated/ln.svg";
import TwitterIcon from "../../assets/Images/Footer/updated/x.svg";
import IGIcon from "../../assets/Images/Footer/updated/ig.svg";
import DigiRyteLogo from "../../assets/Images/Footer/updated/digiryte-logo.svg";

import "../Navbar/Toolbar/Toolbar.scss";
import "./style.scss";

const FooterSection = (props) => {
  const keyExpertise = [
    {
      title: "MVP Development",
      link: "/mvp-development",
    },
    {
      title: "Data Engineering",
      link: "/data-engineering",
    },
    {
      title: "AI / ML",
      link: "/machine-learning",
    },
    {
      title: "Product Scaling",
      link: "/product-scaling",
    },
    {
      title: "Mobile Application Development",
      link: "/mobile-application-development",
    },
    {
      title: "Web Application Development",
      link: "/web-application-development",
    },
    {
      title: "Full Product Development",
      link: "/full-product-development",
    },

    {
      title: "Product Designing & Prototyping",
      link: "/product-designing-and-prototyping",
    },
    {
      title: "Code & UX Audit",
      link: "/code-ux-audit",
    },
    {
      title: "Digital Transformation",
      link: "/digital-transformation",
    },
  ];
  const ourCompany = [
    {
      title: "About us",
      link: "/about-us",
    },
    {
      title: "Leadership",
      link: "/about-us",
    },
    {
      title: "India",
      link: "/india",
    },
    {
      title: "FAQs",
      link: "/faqs",
    },
    {
      title: "Contact us",
      link: "/contact-us",
    },
  ];
  const ourAgileServices = [
    {
      title: "Team Augmentation",
      link: "/team-augmentation",
    },
    {
      title: "Agile Pod",
      link: "/digiryte-pods",
    },
    {
      title: "Product Development",
      link: "/product-development",
    },
  ];
  const ourModel = [
    {
      title: "Our model Overview",
      link: "/our-pod-model/",
    },
    {
      title: "We assemble your team",
      link: "/our-pod-model/#we-build-your-team",
    },
    {
      title: "We handle your operations",
      link: "/our-pod-model/#we-handle-your-operation",
    },
    {
      title: "You grow without limits",
      link: "/our-pod-model/#you-grow-without-limits",
    },
  ];
  const ourInsights = [
    {
      title: "Who we work with",
      link: "/who-we-work-with",
    },
    {
      title: "Articles",
      link: "/insights/articles",
    },
    {
      title: "White Papers",
      link: "/insights/white-papers",
    },
  ];
  const ourPolicy = [
    {
      title: "Privacy Policy",
      link: "/privacy-policy",
    },
    {
      title: "Cookie Policy",
      link: "/cookie-policy",
    },
  ];
  const socialIcons = [
    {
      link: "https://www.linkedin.com/company/digirytey",
      icon: LinkedInIcon,
    },
    {
      link: "https://twitter.com/digiryte",
      icon: TwitterIcon,
    },
    {
      link: "https://www.facebook.com/digiryte",
      icon: FacebookIcon,
    },
    {
      link: "https://instagram.com/digiryte?igshid=YzcxN2Q2NzY0OA==",
      icon: IGIcon,
    },
  ];
  return (
    <div>
      <div className="footer-wrap">
        <div className="footer-container body-container">
          <div className="footer-col-1">
            <div className="f-inner-col-1">
              <div className="footer-menu-wrap">
                <div className="footer-menu-title">Key Expertise</div>
                <div className="footer-menu-list">
                  {keyExpertise.map((item, index) => (
                    <Link
                      key={index}
                      className="footer-menu-item"
                      to={item.link}
                    >
                      <p>{item.title}</p>
                    </Link>
                  ))}
                </div>
              </div>
              <div className="footer-menu-wrap">
                <div className="footer-menu-title">Company</div>
                <div className="footer-menu-list">
                  {ourCompany.map((item, index) => (
                    <Link
                      key={index}
                      className="footer-menu-item"
                      to={item.link}
                    >
                      <p>{item.title}</p>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            <div className="f-inner-col-2">
              <div className="footer-menu-wrap">
                <div className="footer-menu-title">Our Engagement Models</div>
                <div className="footer-menu-list">
                  {ourAgileServices.map((item, index) => (
                    <Link
                      key={index}
                      className="footer-menu-item"
                      to={item.link}
                    >
                      <p>{item.title}</p>
                    </Link>
                  ))}
                </div>
              </div>
              <div className="footer-menu-wrap">
                <div className="footer-menu-title">Our Pod Model</div>
                <div className="footer-menu-list">
                  {ourModel.map((item, index) => (
                    <Link
                      key={index}
                      className="footer-menu-item"
                      to={item.link}
                    >
                      <p>{item.title}</p>
                    </Link>
                  ))}
                </div>
              </div>
              <div className="footer-menu-wrap">
                <div className="footer-menu-title">Insights</div>
                <div className="footer-menu-list">
                  {ourInsights.map((item, index) => (
                    <Link
                      key={index}
                      className="footer-menu-item"
                      to={item.link}
                    >
                      <p>{item.title}</p>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="footer-col-2">
            <div className="footer-img-wrap">
              <div className="footer-img">
                <img src={ISO_9001Img} alt="" />
              </div>
              <div className="footer-img">
                <img src={ISO_27001Img} alt="" />
              </div>
              <div className="footer-img">
                <img src={HMImg} alt="" />
              </div>
            </div>
            <div className="footer-social-wrap">
              {socialIcons.map((item, i) => (
                <Link
                  key={i}
                  className="footer-icon"
                  to={item.link}
                  target="_blank"
                >
                  <img src={item.icon} alt="" />
                </Link>
              ))}
            </div>
            <div className="footer-menu-wrap">
              <div className="footer-menu-title">Global Headquarters</div>
              <div className="footer-location">
                Digiryte Limited,
                <br />
                Blue Tower,
                <br />
                MediaCityUK,
                <br />
                Salford, M50 2ST
                <br />
                Greater Manchester,
                <br />
                England, UK.
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom-wrap">
          <div className="body-container">
            <div className="footer-bottom-inner-wrap">
              <div className="footer-bottom-container">
                <div className="footer-logo-wrap">
                  <img src={DigiRyteLogo} alt="DigiRyte" />
                </div>
                <div className="footer-bottom-menu">
                  {ourPolicy.map((item, index) => (
                    <Link
                      key={index}
                      className="footer-bottom-item"
                      to={item.link}
                    >
                      <p>{item.title}</p>
                    </Link>
                  ))}
                </div>
              </div>
              <div className="footer-bottom-container">
                <div className="footer-copyright">
                  All rights reserved. © {new Date().getFullYear() - 1} -{" "}
                  {new Date().getFullYear()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterSection;
