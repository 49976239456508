import { Link } from "gatsby";
import React from "react";
import MobileNav from "../../Navbar/SideDrawer/DrawerToggleButton";
import "./SideDrawer.scss";

const sideDrawer = (props) => {
  let drawerClasses = "side-drawer";
  if (props.show) {
    drawerClasses = "side-drawer open";
  }
  return (
    <React.Fragment>
      <nav className={drawerClasses}>
        {/* <img className="logo-padding" onClick={() => {props.close()}} src={closeWhiteSvg} alt="Close" /> */}
        {/* <Toolbar close={true} onclose={props.close} /> */}
        <MobileNav close={true} onclose={props.close} />
        <ul>
          <li>
            <Link
              activeStyle={{ color: "black" }}
              activeClassName="currentLink"
              to="/"
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              activeStyle={{ color: "black" }}
              partiallyActive={true}
              to="/our-pod-model"
            >
              Our Pod Model
            </Link>
          </li>
          <li>
            <Link
              activeStyle={{ color: "black" }}
              partiallyActive={true}
              to="/what-we-do"
            >
              What we do
            </Link>
          </li>
          <li>
            <Link
              activeStyle={{ color: "black" }}
              partiallyActive={true}
              to="/who-we-work-with"
            >
              Who we work with
            </Link>
          </li>
          <li>
            <Link
              activeStyle={{ color: "black" }}
              partiallyActive={true}
              to="/digiryte-pods"
            >
              Dedicated Agile Teams
            </Link>
          </li>
          <li>
            <Link
              activeStyle={{ color: "black" }}
              partiallyActive={true}
              to="/insights"
            >
              Insights
            </Link>
          </li>
          <li>
            <Link
              activeStyle={{ color: "black" }}
              partiallyActive={true}
              to="/about-us"
            >
              About Us
            </Link>
          </li>
          <li>
            <Link
              activeStyle={{ color: "black" }}
              partiallyActive={true}
              to="/contact-us"
            >
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </React.Fragment>
  );
};

export default sideDrawer;
